<template>
  <b-modal id="tiss-batch-files-modal" hide-header hide-footer centered size="lg" @hidden="closeModal">
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">Arquivos Anexados</p>
      <div>
        <b-button variant="link" class="mr-2" @click="modalState = 'upload'">
          Anexar Arquivos
        </b-button>
        <Close class="icon" @click="closeModal" />
      </div>
    </div>

    <div class="modal-body" v-if="modalState === 'upload'">
      <b-row class="md-content">
        <div @drop="dragFile" class="area" @click="$refs.FileInput.click()">
          <div v-if="!file">
            Clique aqui ou solte o arquivo nessa área para selecionar...
          </div>
          <span v-if="file" class="file-name">{{ file.name }}</span>
          <div>
            <b-button class="buttons" v-if="file" variant="outline-danger" @click="clearSelectedFile">
              Cancelar
            </b-button>
            <b-button v-if="file" class="ml-2" size="lg" variant="primary" @click="uploadFile">
              Enviar
            </b-button>
          </div>
        </div>
        <input class="d-none" ref="FileInput" type="file" @change="onFileSelect" />
      </b-row>
    </div>

    <div class="modal-body" v-if="modalState === 'download'">
      <perfect-scrollbar>
        <!-- Nenhum arquivo encontrado -->
        <div v-if="!batchFilesXML.length" class="d-flex justify-content-center align-items-center py-5">
          <div class="py-5">
            <span>Nenhum arquivo XML encontrado</span>
          </div>
        </div>

        <!-- XMLs Gerados pelo sistema -->
        <b-row class="all-imports">
          <b-col cols="12" v-for="fileUploaded in batchFilesXML" :key="fileUploaded.id"
            class="progress-content d-flex justify-content-between align-items-center">
            <div>
              <strong>XML</strong>
              <br />
              <span>{{ fileUploaded.filename }}</span>
            </div>
            <b-button variant="link" @click="downloadFile(fileUploaded)">
              Download
            </b-button>
          </b-col>
        </b-row>

        <!-- Arquivos enviados pelo usuário -->
        <b-row class="all-imports">
          <b-col cols="12" v-for="fileUploaded in batchFilesUploaded" :key="fileUploaded.id"
            class="progress-content d-flex justify-content-between align-items-center">
            <div>
              <span class="d-inline-block xml-name">{{ fileUploaded.filename }}</span>
              <br />
              <span>{{ moment(fileUploaded.created_at).format('D [de] MMMM [de] YYYY') }}</span>
            </div>
            <div>
              <b-button variant="link" @click="downloadFile(fileUploaded)">
                Download
              </b-button>
              <b-button variant="link" @click="editFile(fileUploaded.id)" disabled>
                Editar
              </b-button>
              <b-button variant="link" @click="deleteFile(fileUploaded.id)" disabled>
                Excluir
              </b-button>
            </div>
          </b-col>
        </b-row>
      </perfect-scrollbar>
    </div>

    <div class="modal-footer">
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="closeModal">
          Fechar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
  
<script>
import Close from '@/assets/icons/close.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/tiss/manageGuides/api'

export default {
  name: 'ImportTissXMLModal',
  components: {
    Close
  },
  props: {
    batchId: String,
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      file: null,
      filesUploaded: [],
      uploads: [],
      modalState: 'download',
    }
  },
  methods: {
    async getTissBatchFiles() {
      const isLoading = this.$loading.show()

      try {
        const response = await api.getTissBatchFiles(this.clinic.id, this.batchId)
        this.filesUploaded = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal(ev) {
      this.$bvModal.hide('tiss-batch-files-modal')
      this.modalState = 'download'
    },
    dragFile(e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = e.dataTransfer.files[0]
    },
    clearSelectedFile(ev) {
      if (ev?.type !== 'hidden') {
        ev.stopPropagation()
        this.$refs.FileInput.value = null
      }
      this.file = null
    },
    onFileSelect(e) {
      this.file = e.target.files[0]
    },
    async uploadFile(ev) {
      ev.stopPropagation()
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('clinic_id', this.clinic.id)
      formData.append('tiss_batch_id', this.batchId)
      formData.append('filename', this.file.name)
      const isLoading = this.$loading.show()
      try {
        const response = await api.uploadTisssBatchFile(formData)
        this.clearSelectedFile(ev)
        this.$toast.success('Arquivo enviado com sucesso')
        this.uploads.unshift(response.data)
        this.modalState = 'download'
        await this.getTissBatchFiles()
      } catch (error) {
        const message = error?.response?.data?.message
        if (
          [
            'Arquivo inválido',
            'Lote não encontrado'
          ].includes(message)
        ) {
          this.$toast.error(message)
          return
        }
        this.$toast.error(
          'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.'
        )
      } finally {
        isLoading.hide()
      }
    },
    async downloadFile(file) {
      // hofix to download file, need to improve this
      const { data } = await this.api.getDocTemporaryUrl(file.path)
      window.open(data, '_blank')
    },
    resetModal() {
      this.file = null
      this.uploads = []
      this.modalState = 'download'
      this.filesUploaded = []
    }
  },
  computed: {
    batchFilesXML() {
      return this.filesUploaded.filter(file => file.download_only)
    },
    batchFilesUploaded() {
      return this.filesUploaded.filter(file => !file.download_only)
    }
  },
  watch: {
    batchId: {
      handler() {
        if (this.batchId) {
          this.resetModal()
          this.getTissBatchFiles()
        }
      }
    }
  }
}
</script>
  
<style lang="scss">
#tiss-batch-files-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .area {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75%;
        min-height: 32vh;
        border: 1px dashed var(--type-placeholder);
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--neutral-100);
        margin-bottom: 4%;
      }

      .file-name {
        padding: 10px;
        color: var(--type-placeholder);
      }
    }

    .md-content {
      margin: 0 auto;
      padding: 30px 20px 0 20px;
    }

    .all-imports {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      font-size: 0.95em;
      max-height: 30vh;

      .progress-content {
        border-radius: 8px;
        padding: 20px;
        // background-color: var(--neutral-100);
        margin-bottom: 3%;
      }

      .filename {
        text-align: left;

        .xml-name {
          max-width: 90%;
        }
      }
    }
  }
}
</style>
  
  